/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { Link } from 'react-router-dom'
import { ReactSVG } from 'react-svg'

import './LayoutFooter.css'

const LogoLukiil = ({ color, width, height, className }) => {
  const style = {
    display: 'inline-block',
    fill: color || 'currentColor',
    width: width || 'auto',
    height: height || 'auto',
  }
  return <ReactSVG src="/img/logo/lukiil-logo-name.svg" style={style} className={className} />
}
const LogoKoaji = ({ color, width, height, className }) => {
  const style = {
    display: 'inline-block',
    fill: color || 'currentColor',
    width: width || null,
    height: height || null,
  }
  return <ReactSVG src="/img/logo/koaji-logo.svg" style={style} className={className} />
}

export default function LayoutFooter() {
  return (
    <footer className="app-footer-wrapper">
      <div className="app-footer-link-zone">
        <div className="app-footer-menu">
          <div className="app-footer-col">
            <nav>
              <h3>Application</h3>
              <Link to="/">À propos</Link>
              <a rel="noopener noreferrer">Notre Equipe</a>
              <a target="_blank" rel="noopener noreferrer">
                Blog
              </a>
            </nav>
          </div>
          <div className="app-footer-col">
            <nav>
              <h3>Politique</h3>
              <Link to="/cgu">CGU / CGV</Link>
              <a rel="noopener noreferrer">Données personelle</a>
              <a rel="noopener noreferrer">Cookies</a>
            </nav>
          </div>
          <div className="app-footer-col">
            <nav>
              <h3>Business</h3>
              <a rel="noopener noreferrer">Investisseur</a>
              <a rel="noopener noreferrer">Press Kit</a>
            </nav>
          </div>
        </div>

        <div className="app-footer-logo">
          <div className="footer-logo-wrapper">
            <LogoKoaji height="100%" width="4em" className="footer-logo footer-logo-koaji" />
            <span>
              <LogoLukiil height="100%" width="8em" className="footer-logo footer-logo-lukiil" /> By
              Koaji
            </span>
          </div>
        </div>
      </div>

      <div className="app-footer-social">
        <a href="/" target="_blank" rel="noopener noreferrer">
          <ReactSVG src="/img/brands/github.svg" className="socialIcon" />
        </a>
        <a href="/" target="_blank" rel="noopener noreferrer">
          <ReactSVG src="/img/brands/twitter.svg" className="socialIcon" />
        </a>
        <a href="/" target="_blank" rel="noopener noreferrer">
          <ReactSVG src="/img/brands/facebook.svg" className="socialIcon" />
        </a>
        <a href="/" target="_blank" rel="noopener noreferrer">
          <ReactSVG src="/img/brands/instagram.svg" className="socialIcon" />
        </a>
        <a href="/" target="_blank" rel="noopener noreferrer">
          <ReactSVG src="/img/brands/youtube.svg" className="socialIcon" />
        </a>
        <a href="/" target="_blank" rel="noopener noreferrer">
          <ReactSVG src="/img/brands/linkedin.svg" className="socialIcon" />
        </a>
      </div>
      <div className="app-footer-copyright">Copyright © 2020 KOAJI SARL.</div>
    </footer>
  )
}
