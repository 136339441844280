import styled, { css } from 'styled-components'

export const SliceWrapContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  color: ${({ theme }) => theme.default || 'currentColor'};
  background-color: ${({ theme }) => theme.enhancement || 'transparent'};

  & h1,
  & h2,
  & h3 {
    color: ${({ theme }) =>
      (theme?.main !== '#6d7cff' ? theme.main : 'transparent  ') || '#0097a7'};
    background-image: linear-gradient(45deg, #6d7cff, transparent);
    background-color: #bf3dbf;
    -webkit-background-clip: text;
    background-clip: text;
    background-size: 60%;
    background-repeat: no-repeat;
  }
`

export const SliceWrap = styled.div`
  box-sizing: border-box;
  display: flex;
  width: 100%;
  padding: 0;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  max-width: ${({ backgroundImage }) => (backgroundImage?.fullBackground ? 'none' : '1450px')};

  ${({ design }) =>
    design === 'quote' &&
    css`
      max-width: 950px;
      font-weight: 600;
      font-size: 1.1em;
      color: #a795ab;
    `}

  ${({ backgroundImage }) =>
    typeof backgroundImage === 'object' &&
    css`
      min-height: ${backgroundImage?.fullBackground ? '110vh' : 'none'};
      background: radial-gradient(
            at top ${backgroundImage?.pictZone === 'left' ? 'left' : 'right'},
            rgba(0, 0, 0, 0) 0%,
            rgba(0, 0, 0, 0.65) 65%,
            rgba(0, 0, 0, 0.8) 95%
          )
          top left / cover no-repeat,
        url(${backgroundImage.url || ''});
      background-position: center;
      background-size: cover;
      background-attachment: ${backgroundImage.fixed ? 'fixed' : 'scroll'};
      background-repeat: no-repeat;

      & .md-container {
        /* mix-blend-mode: difference; */
        color: #fff;
        text-shadow: 0 0 4px #222;

        h1,
        h2,
        h3 {
          color: currentColor;
        }
      }
    `};

  & > * {
    flex: 1 0 0;
  }

  @media (max-width: 449px) {
    justify-content: center;

    & > * {
      flex: 0 0 0;
    }
    & > *:empty {
      display: none;
    }
  }

  @media (min-width: 450px) {
    &.row {
      padding: ${({ backgroundImage }) => (backgroundImage?.fullBackground ? '15vh 1em' : '1em')};
      flex-direction: row;
      flex-direction: ${({ reverseOnDesk }) => (reverseOnDesk ? 'row-reverse' : 'row')};
      justify-content: center;
      align-items: ${({ backgroundImage }) =>
        backgroundImage?.fullBackground ? 'center' : 'flex-start'};
      text-align: left;

      ${({ reverseOnDesk }) =>
        reverseOnDesk
          ? css`
              & > *:not(:first-child) {
                margin-right: 1em;
              }
              & > *:not(:last-child) {
                margin-left: 1em;
              }
            `
          : css`
              & > *:not(:first-child) {
                margin-left: 1em;
              }
              & > *:not(:last-child) {
                margin-right: 1em;
              }
            `};
    }

    &.column {
      padding: 0;
      flex-direction: ${({ reverseOnDesk }) => (reverseOnDesk ? 'column-reverse' : 'column')};
      justify-content: flex-start;
      align-items: center;
      text-align: center;
    }

    & + *:empty {
      display: none;
    }
  }
`
