import React, { Fragment } from 'react'
import styled from 'styled-components'

const IconClose = styled.button.attrs((props) => ({
  ...props,
  children: (
    <Fragment>
      <div className="first" />
      <div className="middle" />
      <div className="last" />
    </Fragment>
  ),
}))`
  position: absolute;
  top: 1rem;
  right: 1rem;
  width: 3rem;
  height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0 solid;
  background: none;
  outline: none;
  transform: translateX(0);
  transition: transform ease 0.25s;

  & div {
    width: 60%;
    height: 2px;
    position: absolute;
    background-color: #fff;
    transition: transform ease 0.25s;
    transition-delay: 0.25s;
    transition-timing-function: ease;
    transition-property: transform, background-color;

    &.first {
      transform: translateY(0px) rotate(45deg);
    }
    &.middle {
      transform: scaleX(0);
    }
    &.last {
      transform: translateY(0px) rotate(-45deg);
    }
  }

  &:hover div {
    &.first {
      transform: translateX(-0.6em) translateY(0.4em) rotate(45deg) scaleX(0.5);
    }
    &.middle {
      transform: scaleX(1);
    }
    &.last {
      transform: translateX(-0.6em) translateY(-0.4em) rotate(-45deg) scaleX(0.5);
    }
  }
`

export default IconClose
