import 'intersection-observer' // optional polyfill

import React, { Component, useContext } from 'react'
import ReactMarkdown from 'react-markdown'
import Observer from '@researchgate/react-intersection-observer'
import { ReactSVG } from 'react-svg'

import { Button } from 'components'

// import HeaderBar from './HeaderBar'
import './LayoutHeader.css'
import { appContext } from 'context'

const md = `Le centre ville au creux de votre main  
Parce que parfois on sait à la fois,  
ce qu’on veut, et chez qui on le veut.  
`

const LogoLukiil = ({ color, width, height }) => {
  const style = {
    fill: color || 'currentColor',
    width: width || 'auto',
    height: height || 'auto',
    display: 'inline-block',
  }
  const sizeProps = { style, width: width || 'auto', height: height || 'auto' }
  return <ReactSVG src="/img/logo/lukiil-logo-name.svg" {...sizeProps} />
}

const BtnSubscribe = () => {
  const { modeApp, setModeApp } = useContext(appContext)
  const handleClick = () => {
    const value = modeApp === 'form' ? null : 'form'
    setModeApp(value)
  }
  return <Button onClick={handleClick}>Je souhaite m‘inscrire.</Button>
}

export default class LayoutHeader extends Component {
  static propTypes = {}

  options = {
    onChange: this.handleIntersection,
  }

  handleIntersection(event) {
    console.log('handleIntersection >', event.isIntersecting)
  }

  render() {
    return (
      <Observer {...this.options}>
        <section className="layout-header-wrapper">
          {/* <HeaderBar /> */}
          <div className="layout-header">
            <div className="text">
              <LogoLukiil width="10em" />
              <ReactMarkdown source={md} />
            </div>

            <p>
              <BtnSubscribe />
            </p>
          </div>
        </section>
      </Observer>
    )
  }
}
