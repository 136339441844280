import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { ThemeProvider } from 'styled-components'

import { theme as appTheme } from 'config'
import { SliceWrapContainer, SliceWrap } from './SliceWrapper.styled'

import './SliceWrapper.css'

export default class SliceWrapper extends Component {
  static propTypes = {
    children: PropTypes.node,
    direction: PropTypes.oneOf(['row', 'column']),
    reverseOnDesk: PropTypes.bool,
    backgroundImage: PropTypes.shape({
      url: PropTypes.string,
      fixed: PropTypes.bool,
      fullBackground: PropTypes.bool,
    }),
    theme: PropTypes.oneOf(['color']), // TODO : Update this values
  }

  static defaultProps = {
    direction: 'row',
    reverseOnDesk: false,
  }

  render() {
    const {
      backgroundImage,
      children,
      direction,
      reverseOnDesk,
      theme = 'default',
      design,
    } = this.props

    console.log(appTheme, theme, appTheme[theme])

    return (
      <ThemeProvider theme={appTheme[theme]}>
        <SliceWrapContainer
          // theme={appTheme[theme]}
          className={`${theme ? `---theme-${theme}` : ''}`}
        >
          {backgroundImage?.fullBackground ? (
            <SliceWrap className={direction} backgroundImage={backgroundImage}>
              <SliceWrap
                className={`wrapper ${direction}`}
                reverseOnDesk={reverseOnDesk}
                design={design}
              >
                {children}
              </SliceWrap>
            </SliceWrap>
          ) : (
            <SliceWrap
              className={direction}
              reverseOnDesk={reverseOnDesk}
              backgroundImage={backgroundImage}
              design={design}
            >
              {children}
            </SliceWrap>
          )}
        </SliceWrapContainer>
      </ThemeProvider>
    )
  }
}
