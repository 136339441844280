import React, { Fragment, useState, useContext } from 'react'
import { BrowserRouter as Router } from 'react-router-dom'

import Layout from 'layout'
import IconClose from 'IconClose'
import './App.css'

import { withRouteProvider } from 'react-router-json-routes-loader'
import { routeProviderOptions } from 'config'
import { appContext } from 'context'

function FormWrapper() {
  const { modeApp, setModeApp } = useContext(appContext)

  return (
    <div className={`connexion-cols ${modeApp === 'form' ? 'form' : ''}`}>
      <IconClose onClick={() => setModeApp(null)} />
      <iframe
        title="Inscription Lukiil"
        className="iframe-form"
        src="https://services780185.typeform.com/to/HAdkQXrO"
        width="100%"
        height="100%"
      />
    </div>
  )
}

function App() {
  const { modeApp, isMenuOpen } = useContext(appContext)

  return (
    <div className={`App ${modeApp === 'form' ? 'form' : ''} ${isMenuOpen ? 'is-menu-open' : ''}`}>
      <Router>
        <Layout.Switch userProps="Hey ! It's me, Luigi ! " />
      </Router>
    </div>
  )
}

function AppWrapper() {
  const [modeApp, setModeApp] = useState(null)
  const [isMenuOpen, setIsMenuOpen] = useState(false)

  return (
    <appContext.Provider value={{ modeApp, setModeApp, isMenuOpen, setIsMenuOpen }}>
      <Fragment>
        <App />
        <FormWrapper />
      </Fragment>
    </appContext.Provider>
  )
}

export default withRouteProvider(AppWrapper, routeProviderOptions)
