import React, { Fragment, PureComponent } from 'react'
import JcodParser from 'jcod-react-parser'
import { Route, Switch } from 'react-router-dom'

import * as templates from 'templates'

import { LayoutHeader, LayoutFooter } from './components'

import { withRouteContext } from 'react-router-json-routes-loader'
// import { withRouteContext } from "extra/react-router-json-routes-loader";

const parserOption = {
  htmlElement: true,
  // customElement: true,
  // allowElements: ['div', 'popup-info'],
  allowElements: ['popup-info'],
}

class Layout extends PureComponent {
  render() {
    const { dataRoute } = this.props
    console.log('dataRoute >>', dataRoute)
    return (
      <Fragment>
        <LayoutHeader buttonString="Yhea yhea mano !" />
        {/* <LayerWrapper /> */}
        {/* {userProps} ::: {path}
        <hr /> */}

        <section className="layout-content">
          <JcodParser components={templates} data={dataRoute.jcodContent} options={parserOption} />
        </section>

        <LayoutFooter />
      </Fragment>
    )
  }
}

export default withRouteContext(Layout, Route, Switch)
